import { CommonValidationMessages } from "../../Messages";

export const ValidationRulesForBasicInformation = [
    {
        entryTypeId: 1,
        validationRules: {
            eventName: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "event name"),
                },
            ],
            eventTypeId: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry type"),
                }
            ],
            selectedTopicIds: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry topic"),
                }
            ],
            eventNotificationPeriod: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "notification period"),
                },
                {
                    type: "number",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "only numbers in notification period"),
                },
            ],
            eventAbout: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "description about entry"),
                }
            ],
            eventImage: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry image"),
                }
            ],
            outlineDescription: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "outline description"),
                }
            ],

            // speakerDescription: [
            //     {
            //         type: "require",
            //         message: CommonValidationMessages.FieldRequired.replace("{0}", "speaker description"),
            //     }
            // ],
            readMoreDescription: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "read more description"),
                }
            ],
            // speakerImage: [
            //     {
            //         type: "require",
            //         message: CommonValidationMessages.SelectRequired.replace("{0}", "speaker image"),
            //     }
            // ],
        }
    },
    {
        entryTypeId: 2,
        validationRules: {
            eventName: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "event name"),
                },
            ],
            eventTypeId: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry type"),
                }
            ],
            selectedTopicIds: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry topic"),
                }
            ],
            eventNotificationPeriod: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "notification period"),
                },
                {
                    type: "number",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "only numbers in notification period"),
                },
            ],
            eventAbout: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "description about entry"),
                }
            ],
            eventImage: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry image"),
                }
            ],
        }
    },
    {
        entryTypeId: 3,
        validationRules: {
            eventName: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "event name"),
                },
            ],
            eventTypeId: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry type"),
                }
            ],
            selectedTopicIds: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry topic"),
                }
            ],
            eventNotificationPeriod: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "notification period"),
                },
                {
                    type: "number",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "only numbers in notification period"),
                },
            ],
            eventAbout: [
                {
                    type: "require",
                    message: CommonValidationMessages.FieldRequired.replace("{0}", "description about entry"),
                }
            ],
            eventImage: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "entry image"),
                }
            ],
            eventFormStartDate: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "start date"),
                }
            ],
            eventFormEndDate: [
                {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "end date"),
                }
            ],
            // eventSalesEndDate: [
            //     {
            //         type: "require",
            //         message: CommonValidationMessages.SelectRequired.replace("{0}", "sales end date"),
            //     }
            // ],
        }
    }
];

export function RemoveValidation(validationRulesArr, typeId, ruleToRemove) {
    if (typeId > 0) {
        const valObj = validationRulesArr.find(x => x.entryTypeId === typeId).validationRules;
        const objIndex = validationRulesArr.indexOf({ entryTypeId: typeId, validationRules: valObj });
        let valRuleObj = { ...valObj };
        delete valRuleObj[ruleToRemove];
        validationRulesArr.find(x => x.entryTypeId === typeId).validationRules = valRuleObj;
    }
    return validationRulesArr;
}

export function AddValidation(validationRulesArr, typeId, addThisValidations) {
    // validationTypes = [];
    // validationMessages = [];
    //let addThisValidations = [{for : 'businessContactNumber', types : ['require', 'number']}];
    if (typeId > 0) {
        addThisValidations.forEach((v, i) => {
            const valObj = validationRulesArr.find(x => x.entryTypeId === typeId).validationRules;
            const objIndex = validationRulesArr.indexOf({ entryTypeId: typeId, validationRules: valObj });
            let valRuleObj = { ...valObj };
            // valRuleObj[ruleToAdd] = [];
            let arrayToAdd = [];
            v.types.forEach((t, n) => {
                let obj = validationObjectArray.find(x => x.type === t);
                obj.message = CommonValidationMessages.FieldRequired.replace("{0}", v.text);
                arrayToAdd.push(obj);
            });
            valRuleObj[v.for] = arrayToAdd;
            validationRulesArr.find(x => x.entryTypeId === typeId).validationRules = valRuleObj;
        });
    }
    return validationRulesArr;
}

export const validationObjectArray = [
    {
        type: "require",
        message: 'Please enter {0}',
    },
    {
        type: "number",
        message: 'Please enter {0}',
    },
    {
        type: "maxLength",
        message: 'Please enter {0}',
    },
    {
        type: "minLength",
        message: 'Please enter {0}',
    }
]
