import React, { Component } from 'react'
import Pagination from "../../../components/table/CommonPagination"
import { getAuthProps } from '../../../utils/AuthenticationLibrary';
import SwalServices from '../../../services/swalServices/SwalServices';
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import Table from '../../../components/table/Table';
import Selector from '../../../components/dropdown/commonDropdown/Selector';
import { components } from 'react-select';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
        className='form-checkbox h-12'
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


export default class FeaturedEvents extends Component {
  constructor(props) {
    super(props)
    this.swalServices = new SwalServices();
    this.authUser = getAuthProps()
    this.state = {
      totalResultes: "",
      entryNameList: [
        {
          value: 1,
          label: "Global Tech Business Outlook 2021"
        },
        {
          value: 2,
          label: "Global Tech Business Outlook 2022"
        },
        {
          value: 3,
          label: "Global Tech Business Outlook 2023"
        },
        {
          value: 4,
          label: "Global Tech Business Outlook 2024"
        },
        {
          value: 5,
          label: "Global Tech Business Outlook 2025"
        },
      ],
      featuredEventsList: [
        {
          membershipEventId: 1,
          code: 1001,
          entryName: 'Global Tech Business Outlook 2021',
          date: "21 Dec 2022",
          price: 200,
        },
        {
          membershipEventId: 2,
          code: 1002,
          entryName: 'Global Tech Business Outlook 2022',
          date: "21 Dec 2022",
          price: 200,
        },
        {
          membershipEventId: 3,
          code: 1003,
          entryName: 'Global Tech Business Outlook 2023',
          date: "21 Dec 2022",
          price: 200,
        },
      ],
      statuses: [],
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByEntry: 0,
        FilterByStatus: 0
      },

    }
  }
  onEdit = (value, index) => {

  }
  onDelete = (value, index) => {

  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
  }

  actions = (element, index, value) => {
    return (element !== "membershipEventId") ? null
      :
      <td>
      <ActionContainer>
        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDelete}
          text="Delete"
        />
      </ActionContainer>
      </td>
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    var details = this.state.pagination;
    if (drpIdentity === "EntryName") {
      details.filterByEntry = "";

      var dataArr = this.state.entryNameList;
      dataArr.entryNameList = selected;
      if (dataArr.entryNameList.length > 0) {
        var arr1 = dataArr.entryNameList.map(function (item) {
          //delete item.label;
          return item.value;
        });
        details.filterByEntry = arr1.join([',']);
      }
    }
    this.setState({ pagination: details },
    );
  };


  render() {
    return (
      <div className='main-body-section'>
        <div className='flex'>
          <div className="relative flex flex-col flex-1 ">
            <main className='pb-10'>
              <div className='custom-card shadow-lg mb-10'>
                <div className='grid grid-cols-12 gap-6'>
                  <div className='xl:col-span-6'>
                    <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Search by Entry Name</label>
                    {/* <DropdownSelect /> */}
                    {/* Need to add multiselect dropdown */}
                    <Selector
                    
                      options={this.state.entryNameList}
                      isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                      onChange={this.handleChangeInDropdown}
                      allowSelectAll={true}
                      // value={this.state.pagination.selectedStatus}
                      value={this.state.entryNameList.find(item => item.value === this.state.pagination.filterByEntry)}
                      drpIdentity="EntryName"
                    />
                  </div>
                  <div className=" xl:col-span-6">
                    <div className='flex items-end justify-end'>
                      <button className="btn btn-red text-white" aria-controls="add-edit-modal">
                        <span className="text-xl text-white font-semibold tracking-normal">Make Featured Event</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                  <h1 className="table-title-txt theme-color font-bold">
                    {this.state.featuredEventsList.length} Featured Entries
                  </h1>
                </div>
              </div>
              <main className="pb-10">
                <div className="w-full mx-auto">

                  <div className="relative">
                    <div>
                      <div className=" mt-6">
                        <Table columns={[{ name: 'code', title: 'Code' }, { name: 'entryName', title: 'Entry Name' }, { name: 'date', title: 'Date' }, { name: 'price', title: 'Price' }, { name: 'membershipEventId', title: 'Action' }]}
                          pagination={this.state.pagination}
                          rows={this.state.featuredEventsList}
                          sortingColumns={["price"]}
                          isLoading={this.state.isLoading}
                          totalResultes={this.state.totalResultes}
                          totalCount={this.state.totalResultes}
                          setPagination={this.setPagination.bind(this)}
                          customScope={[
                            {
                              column: 'membershipEventId',
                              renderTableData: this.actions.bind(this)
                            },
                          ]}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <div className='no-of-page-title flex justify-end my-10'>
                <p className='text-xl'>
                  Show 10 in a page
                </p>
              </div>
              <div className="px-6 py-8 bg-slate-50 border border-slate-200 rounded-sm">
                <Pagination />
              </div>
            </main>
          </div>
        </div>
      </div>
    )
  }
}

