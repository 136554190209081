import React, { useEffect, useRef, useState } from 'react';
import TimeKeeper from 'react-timekeeper';

export default function TimePicker(props) {

  const [time, setTime] = useState("12:00 AM");
  const [showTime, setShowTime] = useState(false);

  let selectedTime = props.value;

  const OnCloseTimePicker = (selectedTime) => {
    let time = {};
    time.identity = props.identity;
    time.selectedTime = selectedTime.toUpperCase();
    setShowTime(false);
    props.setTime(time);
  }

  const timeRef = useRef(null);


  useEffect(() => {

    function handleClickOutside(event) {
      if (timeRef.current && !timeRef.current.contains(event.target)) {
        setShowTime(false);
        OnCloseTimePicker(selectedTime);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={timeRef}>

      {showTime && (
        <TimeKeeper
          time={time}
          onChange={(newTime) => setTime(newTime.formatted12)}
          onDoneClick={(newTime) => OnCloseTimePicker(newTime.formatted12)}
          switchToMinuteOnHourSelect
        />
      )}
      <div onClick={() => setShowTime(true)} className='p-3 date-pickers time-pikers custom-time-picker flex items-center align-center border border-[#757575] justify-between'>
        <div className='time-display text-[#757575]'>
          <span> {selectedTime} </span>
        </div>
        <div className='btn-time-formate'>
          {!showTime && <button>
            <span className='fa fa-caret-down text-[#757575] text-xl'> </span>
          </button>}
        </div>
      </div>

    </div>
  )
}
