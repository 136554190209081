import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { AddEditMembershipTaskAndSendForApproval, AddEditMembershipTaskAndSendPaymentLink, GetAllBillingCategories, GetAllCorporateProspectSource, GetAllDropdownsForMembershipTaskList, GetAllMembershipRevenue, GetAllMembershipTypes, GetAllPriceTables, GetAllSources, GetAllTasksList, GetAllTransactionaTypes, GetCalculatedFinalPrice, GetExistingDiscountTableByTaskId, GetPriceTabelByCorporateMemberId, GetTaskByCompanyId, SendInvoiceEmailToCorporateMember, SendQuotationEmailToCorporateMember, SendEscalateMailToMember, DeleteTaskListDetailsByTaskId } from '../ApiEndPoints'

export default class MembershipTaskListServices {

    async getAllTaskList(request) {
        return axiosPostAuthorize(GetAllTasksList, request)
    }

    async getTaskByCompanyId(request) {
        return axiosGetAuthorize(GetTaskByCompanyId, request)
    }

    async addEditMembershipTaskAndSendForApproval(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendForApproval, request)
    }
    async getAllSources() {
        return axiosGetAuthorize(GetAllSources);
    }

    async getAllMembershipTypes() {
        return axiosGetAuthorize(GetAllMembershipTypes);
    }

    async getAllTransactionaTypes() {
        return axiosGetAuthorize(GetAllTransactionaTypes);
    }

    async getAllBillingCategories() {
        return axiosGetAuthorize(GetAllBillingCategories);
    }

    async getAllMembershipRevenue() {
        return axiosGetAuthorize(GetAllMembershipRevenue);
    }
    async getPriceTabelForTaskListProcess(request) {
        return axiosPostAuthorize(GetAllPriceTables, request)
    }
    async getSourceLists() {
        return axiosGetAuthorize(GetAllCorporateProspectSource);
    }
    async getAllDropdownsForTaskList() {
        return axiosGetAuthorize(GetAllDropdownsForMembershipTaskList);
    }
    async getPriceTabelByCorporateMemberId(request) {
        return axiosGetAuthorize(GetPriceTabelByCorporateMemberId, request)
    }
    async getCalculatedFinalPrice(request) {
        return axiosPostAuthorize(GetCalculatedFinalPrice, request)
    }
    async addEditMembershipTaskAndSendPaymentLink(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendPaymentLink, request)
    }
    async getExistingDiscountTableByTaskId(request) {
        return axiosGetAuthorize(GetExistingDiscountTableByTaskId, request)
    }
    async sendInvoiceEmailToCorporateMember(request) {
        return axiosPostAuthorize(SendInvoiceEmailToCorporateMember, request);
    }
    async sendQuotationEmailToCorporateMember(request) {
        return axiosPostAuthorize(SendQuotationEmailToCorporateMember, request);
    }
    async sendEscalateMailToMember(request) {
        return axiosPostAuthorize(SendEscalateMailToMember, request);
    }
    async deleteTaskListDetailsByTaskId(request) {
        return axiosGetAuthorize(DeleteTaskListDetailsByTaskId, request);
    }

}
