import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import moment from "moment";
import MembershipFeeApprovalServices from "../../../services/axiosServices/apiServices/MembershipFeeApprovalServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MembershipFeeApprovalModal from "../../../components/membership/membershipFeeApproval/MembershipFeeApprovalModal";
import { CommonValidationMessages, ErrorMessage, SuccessMessage } from "../../../utils/Messages";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import { isValidForm } from "../../../utils/validation/CommonValidator";
import ValidationText from "../../../utils/validation/ValidationText";

export default class MembershipFeeApproval extends Component {
  constructor(props) {
    super(props);
    this.membershipFeeApprovalServices = new MembershipFeeApprovalServices();
    this.TaskListServices = new MembershipTaskListServices();
    this.swalServices = new SwalServices();
    this.state = {
      setDropdownOpen: false,
      setDropdownIndex: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        DateFrom: "",
        DateTo: "",
        filterBySource: 0,
      },
      totalResultes: "",
      pendingFeeApprovalList: [],
      sourceLists: [],
      basePriceTable: [],
      calculatedPriceTable: [],
      percentageDiscountTable: [],
      isLoading: false,
      redirect: null,
      feeApprovalModal: false,
      actionDropdown: ["Process"],
      taskId: 0,
      validationRules: {
        DateTo: [
          {
            type: "minEndDate",
            minEndDate: "DateFrom",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
          }
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
    this.wrapperRef = React.createRef();
  }

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropdownsForTaskList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        var sourceArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateProspectSource"
        );
        this.setState({
          sourceLists: sourceArr,
        }, () => this.getAllPendingFeeApproval());
      } else {
        this.getAllPendingFeeApproval();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  // getSourceList = () => {
  //   this.membershipFeeApprovalServices.getSourceLists().then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ sourceLists: response.responseItem.responseContent });
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isLoading: false });
  //   });
  // };

  getAllPendingFeeApproval = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    // pagination.DateTo = pagination.DateTo ? pagination.DateTo + " " + '23:59:59.000' : '';
    this.membershipFeeApprovalServices.getAllPendingFeeApprovalList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // response.responseItem.responseContent.itemList.forEach(company => {
        //   if (company.companyName.length > 50) {
        //     company.companyName = company.companyName.substring(0, 50) + '...';
        //   }
        // });
        this.setState({
          pendingFeeApprovalList:
            response.responseItem.responseContent.itemList,
        });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.registrationDate = moment(key.registrationDate).format(
            "DD MMM YYYY"
          );
        });
        this.setState({ pendingFeeApprovalList: details });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getPriceTablesForMembershipFeeApproval = (corporateMemberId) => {
    this.membershipFeeApprovalServices.getPriceTablesForMembershipFeeApproval(corporateMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var basePrice = response.responseItem.responseContent.filter(
          (drp) => drp.tableName === "BasePriceTable"
        );
        var percentageDiscount = response.responseItem.responseContent.filter(
          (drp) => drp.tableName === "PercentageDiscountTable"
        );
        basePrice.forEach((element, key) => {
          basePrice[key].status = "Ok";
        });
        if (percentageDiscount.length === 0) {
          percentageDiscount = [];
          basePrice.forEach((element, key) => {
            var obj = {
              row: key + 11,
              year: key + 1,
              entranceFee: 0,
              annualFee: 0,
              includedChapter: 0,
              newChapterPrice: 0,
              tableName: "PercentageDiscountTable",
              status: "Ok",
            };
            percentageDiscount.push(obj);
          });
        }
        this.setState({
          basePriceTable: basePrice,
          percentageDiscountTable: percentageDiscount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  UpdatePriceTablesForMembershipFeeApproval = (corporateMemberId) => {
    // var priceTable = this.state.percentageDiscountTable;
    var request = {}
    if (this.state.taskId > 0) {
      request.taskId = this.state.taskId;
      request.PercentageDiscountTable = this.state.percentageDiscountTable;
      var forPayment = request.PercentageDiscountTable.filter(arr => (arr.status === 'Required Approval' || arr.status === 'Rejected'));
      if (forPayment.length > 0) {
        request.IsApprovalRequired = true;
      }
      else {
        request.IsApprovalRequired = false;
      }
      this.membershipFeeApprovalServices.updatePriceTablesForMembershipFeeApproval(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // var percentageDiscount = response.responseItem.responseContent.filter(drp => drp.tableName === 'PercentageDiscountTable');
          // // if (percentageDiscountTable.length === 0) {
          // //   calculatedPriceTable.forEach((element, key) => {
          // //     var obj = { row: key + 11, year: key + 1, entranceFee: 0, annualFee: 0, includedChapter: 0, newChapterPrice: 0, tableName: 'PercentageDiscountTable', status: 'Ok' };
          // //     percentageDiscountTable.push(obj)
          // //   })
          // // }
          // this.setState({percentageDiscountTable: percentageDiscount });
          this.setState({ feeApprovalModal: false }, () => this.getAllPendingFeeApproval());
          this.swalServices.Success(SuccessMessage.Approval_Success.replace("{0}", "Task"));
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  };

  redirectToProcess = (value, e, taskId) => {
    // {
    e.stopPropagation();
    this.setState({ feeApprovalModal: true });
    this.getPriceTablesForMembershipFeeApproval(value);
    // }
  };

  // handleClickOutside(event) {
  //   if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
  //     this.setState({ setDropdownOpen: false });
  //   }
  // }

  handleChangeInPriceTable(value, name, year, tableName) {
    // var table = this.state.percentageDiscountTable;
    // var allowable = this.state.allowableDiscountTable;
    // var allowableValue = Number(allowable[year][name]);
    // value = isNaN(value) ? 0 : Number(value);
    // if (tableName === 'PercentageDiscountTable') {
    //   table[year][name] = value;
    //   if (value > allowableValue) {
    //     table[year]['status'] = 'Required Approval'
    //   }
    // }
    // this.setState({ percentageDiscountTable: table }, () => this.getCalculatedFinalPrice());
  }

  grantApproval = (name, year, tableName, approval) => {
    var table = this.state.percentageDiscountTable;
    table[year].status = approval;
    // if (tableName === "PercentageDiscountTable") {
    //   table[year].status = approval;
    // if (value > allowableValue) {
    //   table[year]['status'] = 'Required Approval'
    // }
    // }
    this.setState({ percentageDiscountTable: table });
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllPendingFeeApproval();
  };

  onClose = () => {
    this.setState({ setDropdownOpen: false });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllPendingFeeApproval(this.state.pagination);
  };

  actionClick = (index, value, option, e) => {
    var id = this.state.pendingFeeApprovalList[index].corporateMemberId;
    var taskId = this.state.pendingFeeApprovalList[index].taskId ? this.state.pendingFeeApprovalList[index].taskId : 0;
    if (option === "Process") {
      this.setState({ taskId: taskId },
        () => this.redirectToProcess(id, e));
    }
  };

  actions = (element, index, value) => {
    return element !== "CorporateMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  setFilterParameters = (id, drpIdentity) => {
    // let detail = this.state.pagination;
    // if (drpIdentity === "source") {
    //   detail["FilterBySource"] = id;
    //   //this.validateField("resourceTypeId");
    // }
    // this.setState({ pagination: { ...detail } });
    let detail = this.state.pagination;
    if (drpIdentity === "sourceFilter") {
      detail["filterBySource"] = id;
    }
    this.setState({ pagination: { ...detail } });
    // this.getAllPendingFeeApproval(detail);
  };

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ pagination: { ...detail } });
    //this.getAllPendingFeeApproval(detail);
  }

  componentDidMount() {

    this.getAllDropdownsForTaskList();
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // }

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.pagination,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };


  filterByParameters = () => {
    let isValid = true;
    if ((this.state.pagination.DateFrom && this.state.pagination.DateTo && (this.isValidateAllFields() === false ? isValid = false : isValid = true)) || true) {
      if (isValid) {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.pageSize = 10;
        this.setState({ pagination: { ...detail } }, () =>
          this.getAllPendingFeeApproval(detail)
        );
      }
    }
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white pl-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-6 lg:col-span-6 col-span-12 pr-7">
                <h2 className="text-xl theme-color">Date</h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="DateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.DateFrom}
                      onChange={(e) => this.handleChange(e)}
                    />
                    {/* <p>{moment(this.state.pagination.DateFrom).format('yyyy-MM-DD hh:mm:ss:ms')}</p> */}
                  </div>

                  <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="DateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.DateTo}
                      onChange={(e) => this.handleChange(e)}
                    // min={this.state.pagination.DateFrom}
                    />
                    <ValidationText
                      error={this.state.validState.error.DateTo}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2 className="pl-12 block text-xl font-medium  theme-color">
                  Source
                </h2>
                <div className="pl-12 border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    drpIdentity={"sourceFilter"}
                    optionArray={this.state.sourceLists}
                    setFilterParameters={this.setFilterParameters.bind(this)}
                    value={this.state.pagination.filterBySource}
                  />
                </div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <button
                  className="btn btn-red text-xl text-white font-bold"
                  onClick={() => {
                    this.filterByParameters();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
              <div className="text-[#757575] text-xl">Search</div>
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6 action-btn-custom">
                    <Table
                      columns={[
                        {
                          name: "registrationDate",
                          title: "Registration Date",
                        },
                        { name: "FullName", title: "Name" },
                        { name: "Email", title: "Email Address" },
                        { name: "CompanyName", title: "Company Name" },
                        // { name: "Industry", title: "Industry" },
                        { name: "CompanyUEN", title: "Company UEN" },
                        { name: "source", title: "Source" },
                        { name: "CorporateMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.pendingFeeApprovalList}
                      sortingColumns={["source"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "CorporateMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <MembershipFeeApprovalModal
          setFeeApprovalModal={this.state.feeApprovalModal}
          basePriceTable={this.state.basePriceTable}
          percentageDiscountTable={this.state.percentageDiscountTable}
          grantApproval={this.grantApproval.bind(this)}
          setModalOpen={() => this.setState({ feeApprovalModal: false })}
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ feeApprovalModal: false });
          }}
          UpdatePriceTablesForMembershipFeeApproval={this.UpdatePriceTablesForMembershipFeeApproval.bind(this)}
        />
      </>
    );
  }
}
