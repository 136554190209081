import React from "react";

const SectionWithBorder = (props) => {
  return (
    <div className="border border-b-0 pb-3 border-[#e0e0e0] border-solid border-section-main">
      <div
        className={`grid 2xl:grid-cols-3 lg:grid-cols-3 gap-0 h-[50px] bg-zinc-100 w-full items-center`}
      >
        <h2 className="text-base text-[#757575] pl-8">{props.column1}</h2>
        <h2 className="text-base text-[#757575]">{props.column2}</h2>
        <h2 className="text-base text-[#757575]">{props.column3}</h2>
      </div>
      {props.children}
    </div>
  );
};
export default SectionWithBorder;
