import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../components/UIComponent/TextInput";
import ValidationText from "../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import { CommonValidationMessages, ErrorMessage } from "../../utils/Messages";
import { validate, isValidForm } from "../../utils/validation/CommonValidator";
import { checkParameters } from "../../utils/Encryption";

// Import API Service
import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";

export default class AddEditDTSMEMember extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            dTSMEMarketPlaceCompanyId: 0,
            techSolutionList: [
                {
                    id: 1,
                    name: "Tech Solution 1"
                },
                {
                    id: 2,
                    name: "Tech Solution 2"
                },
                {
                    id: 3,
                    name: "Tech Solution 3"
                },
                {
                    id: 4,
                    name: "Tech Solution 4"
                },
                {
                    id: 5,
                    name: "Tech Solution 5"
                },
            ],
            vendorCategoryList: [
                {
                    id: 1,
                    name: "Vendor Category 1"
                },
                {
                    id: 2,
                    name: "Vendor Category 2"
                },
                {
                    id: 3,
                    name: "Vendor Category 3"
                },
                {
                    id: 4,
                    name: "Vendor Category 4"
                },
                {
                    id: 5,
                    name: "Vendor Category 5"
                },
            ],
            dtsmeMemberDetail: {
                dTSMEMarketPlaceCompanyId: 0,
                dTSMEMarketPlaceCompanyName: "",
                contactPersonFirstName: "",
                contactPersonLastName: "",
                contactNo: "",
                email: "",
                url: "",
                description: "",
                companyLogo: "",
                brochureFilePath: "",
                techSolutionId: 0,
                vendorCategoryId: 0,
            },
            validationRule: {
                dTSMEMarketPlaceCompanyName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "DTSME marketplace company name"
                        ),
                    },
                ],
                contactPersonFirstName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "contact person first name"
                        ),
                    },
                ],
                contactPersonLastName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "contact person last name"
                        ),
                    },
                    // {
                    //     type: "isValidateURL",
                    //     message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
                    // },
                ],
                contactNo: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "contact number"),
                    },
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "valid contact number"),
                    }
                ],
                email: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "email"
                        ),
                    },
                    {
                        type: "email",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
                    },
                ],
                url: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "url"
                        ),
                    },
                    {
                        type: "isValidateURL",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
                    },
                ],
                description: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "description"
                        ),
                    },
                ],
                companyLogo: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
                    }
                ],
                brochureFilePath: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "brochure file"
                        ),
                    },
                ],
                techSolutionId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "tech solution"),
                    }
                ],
                vendorCategoryId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "vendor category"),
                    }
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    componentDidMount = () => {
        let params = new URLSearchParams(window.location.search);
        let marketplaceCompanyId = params.get("");
        if (marketplaceCompanyId && (marketplaceCompanyId = checkParameters(marketplaceCompanyId, "Number"))) {
            if (marketplaceCompanyId > 0) {
                this.setState({ dTSMEMarketPlaceCompanyId: marketplaceCompanyId });
            }
        }
        this.getAllDropdownsForDTSME();
        // this.getDTSMEMarketPlaceCompanyInformationById(marketplaceCompanyId);

    }

    // Add Edit DTSME Marketplace Detail
    addEditDTSMEMarketPlaceCompanyInformation = () => {
        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            this.setState({ isLoading: true });
            this.dtsmeServices.addEditDTSMEMarketPlaceCompanyInformation(this.state.dtsmeMemberDetail)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        // if (response.responseItem.responseContent.errorType === 'EXISTS') {
                        //     this.swalServices.Error('This type of authority limit is already exist.');
                        // }
                        // else {
                        this.swalServices.Success("DTSME detail saved sucessfully!");
                        this.setState({ redirect: "/DTSME" });
                        // }
                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
        }
    };


    // Get All Dropdowns for DTSME 
    getAllDropdownsForDTSME = () => {
        this.setState({ isLoading: true });
        this.dtsmeServices.getAllDropdownsForDTSME().then(
            (response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                    let dtsmeTechSolution = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "DTSME TechSolution"
                    );
                    let dtsmeVendorCategory = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "DTSMEVendorCategory"
                    );
                    this.setState({
                        techSolutionList: dtsmeTechSolution,
                        vendorCategoryList: dtsmeVendorCategory
                    });
                } else {
                    this.SwalServices.Error(response.message);
                }
                this.getDTSMEMarketPlaceCompanyInformationById(this.state.dTSMEMarketPlaceCompanyId);
                this.setState({ isLoading: false });
            }
        );
    };

    // Get go global account details by id
    getDTSMEMarketPlaceCompanyInformationById = (id) => {
        if (this.state.dTSMEMarketPlaceCompanyId > 0 && this.state.dTSMEMarketPlaceCompanyId != null) {
            this.dtsmeServices.getDTSMEMarketPlaceCompanyInformationById(id).then((response) => {
                console.log("By Id response :", response);
                if (response.statusCode === 200 && response.responseItem != null) {
                    let res = { ...this.state.dtsmeMemberDetail };

                    res.dTSMEMarketPlaceCompanyId = response.responseItem.responseContent.dtsmeMarketPlaceCompanyId;
                    res.dTSMEMarketPlaceCompanyName = response.responseItem.responseContent.dtsmeMarketPlaceCompanyName;
                    res.contactPersonFirstName = response.responseItem.responseContent.contactPersonFirstName;
                    res.contactPersonLastName = response.responseItem.responseContent.contactPersonLastName;
                    res.contactNo = response.responseItem.responseContent.contactNo;
                    res.email = response.responseItem.responseContent.email;
                    res.url = response.responseItem.responseContent.url;
                    res.description = response.responseItem.responseContent.description;
                    res.companyLogo = response.responseItem.responseContent.companyLogo;
                    res.brochureFilePath = response.responseItem.responseContent.brochureFilePath;
                    res.techSolutionId = response.responseItem.responseContent.techSolutionId;
                    res.vendorCategoryId = response.responseItem.responseContent.vendorCategoryId;

                    // var array = (res.region && res.region !== '0') ? res.region.split(',') : [];
                    // res.selectedRegionArray = [];
                    // array.forEach(topic => {
                    //     res.selectedRegionArray.push(this.state.regionList.find(topics => topics.label === topic));
                    // });
                    this.setState({ dtsmeMemberDetail: res });
                } else {
                    this.swalServices.Error(response.message);
                }
            }
            );
        }
    };

    // Upload Brochure File Method
    onFileChange = (event, identity) => {
        //debugger
        const file = event.target.files[0];
        // if (identity === "Upload_Certificate") {
        if (file && file.type === "application/pdf") {
            let uploadFile = { ...this.state.resFile };
            uploadFile.filename = file.name;
            uploadFile["file"] = file;
            uploadFile.storagePath = "DT-SME";
            let detail = { ...this.state.dtsmeMemberDetail };
            detail.brochureFilePath = file.name;
            this.setState({ dtsmeMemberDetail: detail },
                () => this.validateField("brochureFilePath")
            );
            this.commonServices.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                    let detail = { ...this.state.dtsmeMemberDetail };
                    detail.brochureFilePath = response.fileName;
                    this.setState({ dtsmeMemberDetail: detail });
                    this.swalServices.Success("File Uploaded");
                }
                else {
                    this.swalServices.Error(response.message);
                }
            })
        }
        else {
            this.swalServices.Error("Please Upload only PDF file !");
        }
        // }      
    };

    // Method for Upload Company Logo
    saveMainImage = (file, keyId, imageIdentity) => {
        this.setState({ isLoading: true });
        file.storagePath = "DT-SME\\MarketPlaceCompanyProfileLogo";
        this.commonServices.storeBase64Image(file).then((response) => {
            console.log("Check Image Upload Response :", response);
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                // if (file.storagePath === "DT-SME\\MarketPlaceCompanyProfileLogo") {
                let detail = this.state.dtsmeMemberDetail;
                detail.companyLogo = response.fileName;
                this.setState({ dtsmeMemberDetail: detail }, () => this.validateField("companyLogo"));
                // }
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false })
            console.log("this.state.dtsmeMemberDetail :", this.state.dtsmeMemberDetail);
        })
    }


    // Internal handling Methods
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.dtsmeMemberDetail;
        detail[name] = value;
        this.setState({ dtsmeMemberDetail: { ...detail } });
    }

    // Handle Change In Dropdown Method 
    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.dtsmeMemberDetail;
        if (drpIdentity === "Tech_Solution") {
            detail["techSolutionId"] = id;
            this.validateField("techSolutionId");
        }
        else if (drpIdentity === "Vendor_Category") {
            detail["vendorCategoryId"] = id;
            this.validateField("vendorCategoryId");
        }
        this.setState({ dtsmeMemberDetail: detail });
    };

    // Field validation method 
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.dtsmeMemberDetail,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.dtsmeMemberDetail,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="main-body-section global-account-sec">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                DTSME Member Details
                            </h4>
                        </div>
                        <div className="px-8 pt-10 account-fields">
                            <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    DTSME Marketplace Company Name{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="dTSMEMarketPlaceCompanyName"
                                                            value={this.state.dtsmeMemberDetail.dTSMEMarketPlaceCompanyName}
                                                            identity="dTSMEMarketPlaceCompanyName"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("dTSMEMarketPlaceCompanyName", this.state.dtsmeMemberDetail)}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.dTSMEMarketPlaceCompanyName} />
                                            </div>

                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Contact Person First Name{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="contactPersonFirstName"
                                                            value={this.state.dtsmeMemberDetail.contactPersonFirstName}
                                                            identity="contactPersonFirstName"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("contactPersonFirstName", this.state.dtsmeMemberDetail)}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.contactPersonFirstName} />
                                            </div>

                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Contact Person Last Name{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="contactPersonLastName"
                                                            value={this.state.dtsmeMemberDetail.contactPersonLastName}
                                                            identity="OrganizationProfile"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("contactPersonLastName", this.state.dtsmeMemberDetail)}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.contactPersonLastName} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Contact Number{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-full outline-none appearance-none">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="contactNo"
                                                        value={this.state.dtsmeMemberDetail.contactNo}
                                                        identity="OrganizationProfile"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("contactNo", this.state.dtsmeMemberDetail)}
                                                    />
                                                </div>
                                                <ValidationText error={this.state.validState.error.contactNo} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Email{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="email"
                                                            value={this.state.dtsmeMemberDetail.email}
                                                            identity="email"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("email", this.state.dtsmeMemberDetail)}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.email} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    URL {""}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="url"
                                                    value={this.state.dtsmeMemberDetail.url}
                                                    identity="url"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) =>
                                                        this.validateField(
                                                            "url",
                                                            this.state.dtsmeMemberDetail
                                                        )
                                                    }
                                                />
                                                <ValidationText error={this.state.validState.error.url} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Description {""}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="description"
                                                    value={this.state.dtsmeMemberDetail.description}
                                                    identity="description"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) =>
                                                        this.validateField(
                                                            "description",
                                                            this.state.dtsmeMemberDetail
                                                        )
                                                    }
                                                />
                                                <ValidationText error={this.state.validState.error.description} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    DTSME Tech Solution{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="flex items-center">
                                                    <div className="w-full">
                                                        <DropdownSelect
                                                            drpIdentity={"Tech_Solution"}
                                                            optionArray={this.state.techSolutionList}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.dtsmeMemberDetail.techSolutionId}
                                                        //disabled={true}
                                                        />

                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.techSolutionId} />
                                            </div>
                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    DTSME Vendor Category{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="flex items-center">
                                                    <div className="w-full">
                                                        <DropdownSelect
                                                            drpIdentity={"Vendor_Category"}
                                                            optionArray={this.state.vendorCategoryList}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.dtsmeMemberDetail.vendorCategoryId}
                                                        //disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.vendorCategoryId} />
                                            </div>

                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Upload Brochure File{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="flex item-center flex-col">
                                                    <form className="custom-uploader custom-file-upload-btn flex">
                                                        <div className='border border-[#f3f3f3] btn btn-red cursor-pointer'>
                                                            <label
                                                                htmlFor={`Upload_Brochure_File`}
                                                                className="text-uplod text-white cursor-pointer"
                                                            >
                                                                Upload File
                                                            </label>
                                                            <input
                                                                className="sr-only"
                                                                // key={`FileQuestion${key}`}
                                                                id={`Upload_Brochure_File`}
                                                                type="file"
                                                                onChange={(event) => this.onFileChange(event)}
                                                            />
                                                        </div>
                                                    </form>
                                                    <ValidationText error={this.state.validState.error.brochureFilePath} />
                                                    {
                                                        this.state.dtsmeMemberDetail.brochureFilePath ?
                                                            (
                                                                <div className='mt-5 file-uploaded-txt flex items-center'>
                                                                    <img src='/check.png' className='w-[25px] mr-3' alt=''></img>
                                                                    <span className='text-[#000]'>
                                                                        {this.state.dtsmeMemberDetail.brochureFilePath}
                                                                    </span>
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>



                                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Company Logo
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-[320px]">
                                                    <div className="company-logo-cropper ml-auto mr-auto goglobal-company-logo">
                                                        <ImageCropperWithUpload
                                                            key="Main"
                                                            height={200}
                                                            width={400}
                                                            isCheckValidation={true}
                                                            MaxFileSize={2097152}
                                                            MaxFileSizeMessage={"2MB"}
                                                            onSaveImage={this.saveMainImage}
                                                            imageIdentity="DT-SME\MarketPlaceCompanyProfileLogo"
                                                            intitImage={this.state.dtsmeMemberDetail.companyLogo}
                                                            aspactRatio={400 / 200}
                                                            uploadBtn={"Upload Logo"}
                                                            dimensionText={"(Format: JPG/PNG Dimension: 400px by 200px Maximum Size: 2MB)"}
                                                        />

                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.companyLogo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Footer Button */}
                                <div className="flex flex-wrap justify-end space-x-10 mt-12 pb-8">
                                    <div className="space-x-4">
                                        {
                                            this.state.isLoading ?
                                                <ButtonLoader />
                                                :
                                                <button
                                                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                                    onClick={() => this.addEditDTSMEMarketPlaceCompanyInformation()}
                                                >
                                                    Save
                                                </button>
                                        }
                                    </div>
                                </div>
                                {/*Footer Button End*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}