import React, { Component } from 'react'
import Table from '../../../components/table/Table';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import MembershipDirectoryEnquiryModal from '../../../components/membership/membershipDirectoryEnquiryModal/MembershipDirectoryEnquiryModal';
import moment from 'moment';

//import API services
import WebMembershipCorporateServices from '../../../services/axiosServices/apiServices/WebMembershipCorporateServices';
import SwalServices from '../../../services/swalServices/SwalServices';

export default class MembershipDirectoryEnquiry extends Component {
    constructor(props) {
        super(props);
        this.WebMembershipCorporateServices = new WebMembershipCorporateServices();
        this.SwalServices = new SwalServices();
        this.state = {
            redirect: null,
            pagination: {
                orderByColumn: "",
                orderFlag: 0,
                pageNo: 1,
                pageSize: 10,
                searchText: "",
                senderEmail: "",
                senderCorporateName: "",
                receiverCorporateName: "",
            },
            totalResultes: "",
            councilIndex: 0,
            isLoading: false,
            setMembershipDirectoryEnquiry: false,
            sendEscalation: false,
            membershipDirectoryEnquiryList: [
                {
                    id: 1,
                    enquiryId: 1021,
                    enquiryDate: "21 Dec 2022",
                    senderEmail: "sara@onetech.com.sg",
                    senderCompany: "One Tech",
                    receiverCompany: "ABC Technologies",
                    message: `Lorem ipsum dolor sit amet, consectetuer
                    adipiscing elit, sed diam nonummy nibh
                    euismod tincidunt ut laoreet dolore magna
                    aliquam erat volutpat. Ut wisi enim ad minim
                    veniam, quis nostrud exerci tation
                    ullamcorper suscipit lobortis nisl ut aliquip
                    ex ea commodo consequat.`,
                    senderName: "Sara Tan",
                    contactNo: "45645645646",
                },
            ],
            getMemebrDirectoryEnquiryById: {},
        }
    }

    //Method declarationsection
    componentDidMount() {
        this.getALLMembershipEnquiryList();
    }

    //API method declaration start

    //Get all membership directory enquiry list
    getALLMembershipEnquiryList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.WebMembershipCorporateServices.getALLMembershipEnquiryList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newDirectoryEnquiryList = response.responseItem.responseContent.itemList;
                newDirectoryEnquiryList.map((key) => {
                    key.createdAt = moment(key.createdAt).format("DD MMM YYYY");
                });
                this.setState({ membershipDirectoryEnquiryList: newDirectoryEnquiryList });

                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            } else {
                this.SwalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    getMembershipEnquiryById = (id) => {
        this.WebMembershipCorporateServices.getMembershipEnquiryById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newDirectoryEnquiryById = response.responseItem.responseContent;
                newDirectoryEnquiryById.createdAt = moment(newDirectoryEnquiryById.createdAt).format("DD MMM YYYY");
                this.setState({ getMemebrDirectoryEnquiryById: newDirectoryEnquiryById });
            } else {
                this.SwalServices.Error(response.message);
            }
        }
        );
    };

    //Internal method declaration section start
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getALLMembershipEnquiryList();
    };

    showDirectoryDetail = (e, id, index) => {
        this.setState({ councilIndex: index });
        this.getMembershipEnquiryById(id);
        e.stopPropagation();
        this.setState({ setMembershipDirectoryEnquiry: true });
    };

    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "senderEmail") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.senderEmail = value;
            this.setState({ pagination: { ...detail } });
        }
        if (identity === "senderCorporateName") {
            detail.senderCorporateName = value;
            detail.pageNo = 1;
            detail.pageSize = 10;
            this.setState({ pagination: { ...detail } });
        }
        if (identity === "receiverCorporateName") {
            detail.receiverCorporateName = value;
            detail.pageNo = 1;
            detail.pageSize = 10;
            this.setState({ pagination: { ...detail } });
        }
    };

    actions = (element, index, value) => {
        return element !== "membershipDirectoryEnquiryId" ? null : (
            <td>
                <ActionContainer >
                    <h2
                        className='underline text-[#757575] cursor-pointer'
                        onClick={(e) => this.showDirectoryDetail(e, value, index)}

                    >Preview</h2>
                </ActionContainer >
            </td>
        );
    };

    render() {
        return (
            <div className="main-body-section">
                <div className="custom-card shadow-lg">
                    <div className="filter-info grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 lg:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Sender Email
                                    </label>
                                    <input
                                        id="senderEmail"
                                        name="senderEmail"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "senderEmail")}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Sender Company
                                    </label>
                                    <input
                                        id="eventName"
                                        name="sgTechOic"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "senderCorporateName")}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-5 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Receiver Company
                                    </label>
                                    <input
                                        id="eventName"
                                        name="sgTechOic"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "receiverCorporateName")}
                                    />
                                </div>
                                <div className="xl:col-span-3 lg:col-span-6 col-span-12">
                                    <div className=" patner-search flex items-center justify-between pt-11">
                                        <div className="search">
                                            <button
                                                className="btn btn-red text-xl text-white font-bold"
                                                onClick={() => {
                                                    this.getALLMembershipEnquiryList();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="pb-10 pt-8">
                    <div className="w-full mx-auto">
                        <div className="relative">
                            <div>
                                <div className=" mt-6">
                                    <Table
                                        columns={[
                                            { name: "createdAt", title: "Date", },
                                            { name: "senderEmail", title: "Sender Email" },
                                            { name: "senderCorporateName", title: "Sender Company" },
                                            { name: "receiverCorporateName", title: "Receiver Company" },
                                            { name: "membershipDirectoryEnquiryId", title: "Action" },
                                        ]}
                                        pagination={this.state.pagination}
                                        rows={this.state.membershipDirectoryEnquiryList}
                                        sortingColumns={["date"]}
                                        isLoading={this.state.isLoading}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: "membershipDirectoryEnquiryId",
                                                renderTableData: this.actions.bind(this),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="membership-task-list-process-sec">
                    <MembershipDirectoryEnquiryModal
                        councilIndex={this.state.councilIndex}
                        getMemebrDirectoryEnquiryById={this.state.getMemebrDirectoryEnquiryById}
                        // membershipDirectoryEnquiryList={this.state.membershipDirectoryEnquiryList}
                        paymentLinkModal={this.state.setMembershipDirectoryEnquiry}
                        setOpenModal={() => this.setState({ setMembershipDirectoryEnquiry: false })}
                        onClose={(e) => {
                            e.stopPropagation();
                            this.setState({ setMembershipDirectoryEnquiry: false });
                        }}
                    />
                </div>
            </div>
        )
    }
}