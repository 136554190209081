import React from "react";
import ValidationText from "../../../utils/validation/ValidationText";
import Selector from "../../dropdown/commonDropdown/Selector";
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import TextInput from "../../UIComponent/TextInput";

function CreateAdmin(props) {
  return (
    <div>
      <div className="grid grid-cols-12 gap-6 w-full pb-3">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        First Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="name"
                          value={props.adminDetails.name}
                          identity="adminDetails"
                          handleChange={props.handleChange}
                          onBlur={(e) => props.ValidateAdminDetails("name")}
                        />


                      </div>
                      <ValidationText
                        error={props.validState.error.name}
                      />
                    </div>
                    <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Last Name
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex items-center">
                        <TextInput
                          placeholder=""
                          type="text"
                          name="lastName"
                          value={props.adminDetails.lastName}
                          identity="adminDetails"
                          handleChange={props.handleChange}
                          onBlur={(e) => props.ValidateAdminDetails("lastName")}
                        />

                      </div>
                      <ValidationText
                        error={props.validState.error.lastName}
                      />
                    </div>
                    <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Email
                        <span className="text-[#c00000]">*</span>
                      </h2>
                      <div className="flex items-center">
                        <TextInput
                          placeholder=""
                          type="email"
                          name="email"
                          value={props.adminDetails.email}
                          identity="adminDetails"
                          handleChange={props.handleChange}
                          onBlur={(e) => props.ValidateAdminDetails("email")}
                        />


                      </div>
                      <ValidationText
                        error={props.validState.error.email}
                      />
                    </div>
                    {props.adminId && props.adminId > 0 ?
                      null
                      :
                      <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                        <h2 className="text-xl theme-color mb-1">
                          Password
                          <span className="text-[#c00000]">*</span>
                        </h2>
                        <div className="flex items-center">
                          <TextInput
                            placeholder=""
                            type="password"
                            name="password"
                            value={props.adminDetails.password}
                            identity="adminDetails"
                            handleChange={props.handleChange}
                            onBlur={(e) => props.ValidateAdminDetails("password")}
                          />


                        </div>
                        <ValidationText
                          error={props.validState.error.password}
                        />
                      </div>
                    }

                    {/* Role Start */}
                    <div className="2xl:col-span-11 lg:col-span-12 col-span-12">
                      <h2 className="text-xl theme-color mb-1">
                        Role
                        <span className="text-[#c00000]">*</span>
                      </h2>

                      <div className="dropdwn-input flex items-center">
                        <div className="w-full">
                          <Selector
                            drpIdentity="AdminRole"
                            options={props.adminRoleList}
                            //   options={props.countriesWithPresence}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            // components={{ Option }}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={false}
                            value={
                              props.adminDetails.selectedRole
                            }
                          />
                        </div>
                      </div>
                        <ValidationText
                          error={props.validState.error.selectedRole}
                        />
                    </div>
                    {/* Role End */}
                  </div>


                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="adminaccount-info-croper company-logo-cropper ml-auto mr-auto mt-8">
                <ImageCropperWithUpload
                  key="Main"
                  height={250}
                  width={350}
                  isCheckValidation={true}
                  MaxFileSize={2097152}
                  MaxFileSizeMessage={"2MB"}
                  onSaveImage={props.saveMainImage}
                  imageIdentity="Admin"
                  intitImage={props.adminDetails.profilePic}
                  aspactRatio={350 / 250}
                  uploadBtn={"Upload Profile Photo"}
                  dimensionText={
                    "(Dimension: 200px by 200px Maximum Size: 2MB)"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAdmin;
